.form_content {
    margin-top: -230px;
    padding: 65px 80px 80px 80px;
    background: #fff;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
    border-radius: 40px;
    height: 615px;
    transition: height .3s ease-in-out, opacity .4s ease;

    &.form_content_last_step {
        height: auto;

        @include mediaToDown($container-tablet) {
            height: auto;
        }
    }

    &.loader_show {
        height: 590px;

        @include mediaToDown($container-tablet) {
            height: 400px;
        }

        @include mediaToDown($container-mobile) {
            height: 270px;
        }
    }

    &.loans_content_show {
        height: auto;

        @include mediaToDown($container-tablet) {
            height: auto;
        }

        @include mediaToDown($container-mobile) {
            height: auto;
        }
    }

    &.loans_content_show_second {
        height: auto;

        @include mediaToDown($container-tablet) {
            height: auto;
        }

        @include mediaToDown($container-mobile) {
            height: auto;
        }

        @include mediaToDown(400px) {
            height: auto;
        }
    }

    &.form_content_static {
        height: 615px;

        @include mediaToDown($container-tablet) {
            height: 730px;
        }

        @include mediaToDown($container-mobile) {
            height: 540px;
        }
    }


    &.form_content_first_step {

        @include mediaToDown($container-tablet) {
            height: 550px;
        }

        @include mediaToDown($container-mobile) {
            height: 400px;
        }
    }

    @include mediaToDown($container-tablet) {
        padding: 20px 30px;
    }

    @include mediaToDown($container-mobile) {
        border-radius: 10px;
        padding: 20px;
        margin-top: 0;
        box-shadow: none;
    }
}

.form_content_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mediaToDown($container-mobile) {
        justify-content: center;
    }
}

.form_content_head_steps {
    margin-top: 20px;

    span {
        line-height: 24px;
        font-size: 18px;

        &.green {
            color: #FF971D;
            font-weight: 700;
            font-size: 20px;
        }
    }

    @include mediaToDown($container-mobile) {
        display: none;
    }
}

.form_content_select_wrap {
    display: flex;
    justify-content: space-between;
    padding-top: 22px;

    .form_row {
        width: 508px;
    }

    @include mediaToDown($container-mobile) {
        flex-wrap: wrap;
        padding-top: 20px;
    }
}

.form_content_step_bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    /*padding-top: 50px;
    border-top: 2px solid #F0F2F6;*/

    @include mediaToDown($container-mobile) {
        border-top: none;
        padding-top: 20px;
    }
}

.form_content_step_bottom_left {
    span {
        display: block;
        margin-bottom: 12px;
        line-height: 30px;
        color: color('grey');

        @include mediaToDown($container-mobile) {

            &.random_car_info {
                display: none;
            }
        }
    }
}

.random_car_info {
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.form_content_step_bottom_right {
    .form_row {
        width: 305px;

        @include mediaToDown($container-mobile) {
            width: 100%;
        }
    }
}

.motivation_wrap {
    @include mediaToDown($container-mobile) {
        width: 100%;
    }
}

.form_row_range_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    @include mediaToDown($container-mobile) {
        padding-top: 14px;
    }
}

.form_row_range {
    width: 508px;
    margin-bottom: 25px;

    @include mediaToDown($container-tablet) {
        width: 100%;
    }

    @include mediaToDown($container-mobile) {
        margin-bottom: 16px;
    }
}

.form_payment_title {
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 19px;
    color: color('grey');
    text-align: right;

    @include mediaToDown($container-tablet) {
        text-align: left;
    }

    @include mediaToDown($container-mobile) {
        color: #FF971D;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 0;
    }
}

.form_payment_money {
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0.05em;
    color: #FF971D;
    text-align: right;

    @include mediaToDown($container-mobile) {
        font-size: 20px;
        line-height: 30px;
        text-align: left;
    }
}

.range_slider_info {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    font-size: 12px;
    line-height: 14px;
    color: color('grey');
    opacity: 0.7;
}

.form_content_step_one_bottom {
    @include mediaToDown($container-mobile) {
        .form_content_step_bottom_left {
            width: 0;
        }
        .form_content_step_bottom_right {
            width: 100%;
        }
    }
}

.form_content_step_bottom_left {
    width: 40%;
}

.form_content_step_bottom_right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 60%;
}

.form_content_step_bottom_center {
    margin-right: 38px;
    margin-bottom: 7px;

    @include mediaToDown($container-tablet) {
        position: absolute;
        top: -90px;
        right: 0;
        margin-right: 0;
        margin-bottom: 0;
    }

    @include mediaToDown($container-mobile) {
        top: -44px;
    }
}

.btn_back_step {
    position: relative;
    width: 240px;
    padding-left: 24px;
    margin-bottom: -7px;
    color: color('grey');
    line-height: 30px;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 10px;
        background: url('../../images/back_step.svg') no-repeat center center/cover;

        @include mediaToDown($container-mobile) {
            width: 12px;
            height: 8px;
        }
    }

    @include mediaToDown($container-mobile) {
        width: 165px;
        padding-left: 18px;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 0;
    }
}

.form_content_step_bottom_center_title {
    position: relative;
    width: 160px;
    margin-left: auto;
    margin-bottom: 9px;
    font-size: 16px;
    line-height: 19px;
    color: color('grey');
    text-align: right;

    @include mediaToDown($container-mobile) {
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 0;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 19px;
        height: 3.5px;
        background: url('../../images/icon_grey.svg') no-repeat center center/cover;

        @include mediaToDown($container-mobile) {
            content: none;
        }
    }
}

.form_content_step_bottom_center_credit_amount {
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    text-align: right;
    letter-spacing: 0.05em;

    @include mediaToDown($container-mobile) {
        font-size: 20px;
        line-height: 30px;
    }
}

.form_subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 10px;
    margin-top: -23px;

    &.desktop {
        display: inline-block;
    }
    &.mobile {
        display: none;
    }

    @include mediaToDown($container-mobile) {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        margin-top: 0px;

        &.desktop {
            display: none;
        }
        &.mobile {
            display: inline-block;
        }
    }
}

.form_subtitle_loans {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    @include mediaToDown($container-mobile) {
        font-size: 19px;
        line-height: 20px;
        text-align: center;
    }
}

.form_payment {
    margin-bottom: 23px;

    @include mediaToDown($container-mobile) {
        margin-bottom: 0;
    }
}

.step_two_bottom {

    @include mediaToDown($container-tablet) {
        position: relative;
    }

    @include mediaToDown($container-mobile) {
        border-top: none;
    }
}

.mobile_steps_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;

    &.center {
        justify-content: center;
    }
}

.mobile_steps_nav_number {

    span {
        font-size: 9px;
        line-height: 11px;

        &.green {
            color: #FF971D;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
        }
    }

}

.form_content_step_three_wrap {
    display: flex;
    justify-content: space-between;
    padding-top: 28px;
    margin-bottom: 41px;

    .form_row {
        width: 508px;

        @include mediaToDown($container-tablet) {
            width: 49%;
        }

        @include mediaToDown($container-mobile) {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    @include mediaToDown($container-mobile) {
        flex-wrap: wrap;
        padding-top: 20px;
        margin-bottom: -20px;
    }

    input {

        &::placeholder {
            color: transparent;
        }

        &:focus {
            &::placeholder {
                color: #8E8E9E;
            }
        }
    }
}

.form_content_info {
    transition: opacity .4s ease;
}

.changed_step {
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s ease;
}
