.container {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    @include mediaToDown($container-tablet) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @include mediaToDown($container-mobile) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

*,
::before,
::after {
    box-sizing: border-box;
}

.clearfix {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

.left {
    float: left;
}

.right {
    float: right;
}

.row {
    margin-left: pxToVwHD(-10px);
    margin-right: pxToVwHD(-10px);

    @include mediaToDown($container-tablet) {
        margin-left: pxToVwLayoutSize(-10px);
        margin-right: pxToVwLayoutSize(-10px);
    }

    @include mediaToDown($container-mobile) {
        margin-left: 0;
        margin-right: 0;
    }
}

@mixin grid_column() {
    padding-left: pxToVwHD(15px);
    padding-right: pxToVwHD(15px);
}

.column {
    @include grid_column();
}

h1 {
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    color: color('black');

    @include mediaToDown($container-mobile) {
        font-size: 22px;
        line-height: 24px;
        text-align: center;
    }

    @include mediaToDown(360px) {
        font-size: 19px;
    }
}

h2 {
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    color: color('black');

    @include mediaToDown($container-tablet) {
        font-size: 32px;
        line-height: 50px;
    }

    @include mediaToDown($container-mobile) {
        font-size: 22px;
        line-height: 24px;
        text-align: center;
    }

    @include mediaToDown(360px) {
        font-size: 19px;
    }
}

.mb-50 {
    margin-bottom: 50px;

    @include mediaToDown($container-mobile) {
        margin-bottom: 20px;
    }
}

.hide_mobile {

    @include mediaToDown($container-mobile) {
        display: none !important;
    }
}

.mobile_only {
    display: none;
    width: 100%;

    @include mediaToDown($container-mobile) {
        display: block;
    }
}

.overflow {
    overflow: hidden;
}
