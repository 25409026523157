.advice_form_content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 36px;

    .form_row {
        width: 386px;
    }

    .form_row_checbox {
        padding-top: 25px;
        @include mediaToDown($container-mobile) {
            padding-top: 0;
        }
    }

    @include mediaToDown($container-tablet) {

        .form_row {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    @include mediaToDown($container-mobile) {
        padding-top: 20px;

        .form_row {
            margin-bottom: 10px;

            input {
                background: #F0F2F6;
            }
        }
    }
}
