.section_advantages {
    padding-top: 20px;
    @include mediaToDown($container-mobile) {
        padding-top: 10px;
    }
}

.advantages_wrap {
    display: flex;
    justify-content: space-between;
    @include mediaToDown($container-tablet) {
        flex-wrap: wrap;
    }
}

.advantage_item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 210px;
    width: calc(100% / 4 - 15px);
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
    border-radius: 40px;
    @include mediaToDown($container-tablet) {
        width: calc(100% / 2 - 15px);
        margin-bottom: 30px;
    }
    @include mediaToDown($container-mobile) {
        width: calc(100% / 2 - 5px);
        margin-bottom: 10px;
        height: 125px;
        border-radius: 10px;
    }
}

.advantage_logo_wrap {
    margin-top: 10px;
}

.advantage_logo {
    width: 60px;
    @include mediaToDown($container-mobile) {
        width: 40px;
    }

    img {
        display: block;
        width: 100%;
    }
}

.advantage_text {
    font-size: 18px;
    line-height: 27px;
    padding-top: 18px;
    @include mediaToDown($container-mobile) {
        font-size: 12px;
        line-height: 21px;
        padding-top: 8px;
    }
}
