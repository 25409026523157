.loan_offers_wrap {
    margin-bottom: 10px;
}

.loan_offers_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 25px;

    @include mediaToDown($container-mobile) {
        padding-top: 15px;
    }
}

.loan_offers_item {
    display: flex;
    align-items: center;
    width: 330px;
    height: 80px;
    padding-left: 20px;
    margin-bottom: 20px;
    background: #F0F2F6;
    border-radius: 10px;
    cursor: pointer;
    transition: all .2s ease;

    &.active, &:hover {
        background: #FF971D;

        .loan_offers_item_info_month {
            color: #fff;
        }

        .loan_offers_item_info_rate {
            color: #fff;
        }

        .logo_offers_item_logo {

            img {
                mix-blend-mode: multiply;
            }
        }
    }

    @include mediaToDown($container-tablet) {
        width: 48%;
    }

    @include mediaToDown($container-mobile) {
        flex-wrap: wrap;
        height: auto;
        padding: 10px 13px;
        margin-bottom: 10px;
    }
}

.logo_offers_item_logo {
    display: flex;
    align-items: center;
    width: 48px;
    height: 50px;

    @include mediaToDown($container-mobile) {
        width: 24px;
        height: auto;
    }

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        mix-blend-mode: multiply;
    }
}

.loan_offers_item_info {
    margin-left: 15px;

    @include mediaToDown($container-mobile) {
        width: 100%;
        margin-left: 0;
        margin-top: 8px;
    }
}

.loan_offers_item_info_month {
    font-size: 16px;
    line-height: 19px;
    color: color('grey');

    @include mediaToDown($container-mobile) {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 4px;
    }
}

.loan_offers_item_info_rate {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;

    @include mediaToDown($container-tablet) {
        white-space: nowrap;
    }

    @include mediaToDown($container-mobile) {
        font-size: 16px;
        line-height: 1;
    }
}
