.form_content_years {
    display: flex;
    align-items: center;
    padding-top: 60px;
    margin-bottom: 70px;

    @include mediaToDown($container-tablet) {
        display: block;
    }

    @include mediaToDown($container-mobile) {
        padding-top: 0;
        margin-top: -5px;
        margin-bottom: 0;
        width: calc(100% + 40px);
        margin-left: -20px;
    }
}

.form_content_years_title {
    line-height: 24px;
    color: color('grey');
    width: calc(100% - 835px);

    @include mediaToDown($container-tablet) {
        width: 100%;
        margin-bottom: 10px;
    }

    @include mediaToDown($container-mobile) {
        font-size: 12px;
        padding-left: 24px;
        margin-bottom: 6px;
    }
}

.form_content_years_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 835px;
}

.form_content_years_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 46px;
    border-radius: 10px;
    background: #F0F2F6;
    color: color('grey');
    cursor: pointer;
    transition: background-color .2s ease;

    &.active {
        color: #fff;
        background: #FF971D;
        font-weight: 500;
    }

    @include mediaToDown($container-tablet) {
        margin-right: 15px;
    }

    @include mediaToDown($container-mobile) {
        width: 72px;
        font-size: 20px;
    }

    @include mediaToDown(376px) {
        width: 80px;
    }

    @include mediaToDown(340px) {
        width: 66px;
    }
}

.form_content_years_items_wrap {
    @include mediaToDown($container-tablet) {
        width: 100%;
        overflow: hidden;
    }

    @include mediaToDown($container-mobile) {

    }
}

.form_content_years_items {

    @include mediaToDown($container-tablet) {
        display: flex;
        overflow-x: auto;
        margin-bottom: -15px;
        padding-bottom: 15px;
        padding-left: 20px;
    }
}
