.section_banks {
    padding-top: 120px;

    @include mediaToDown($container-mobile) {
        padding-top: 25px;
    }
}

.banks_wrapper {
    @include mediaToDown($container-tablet) {
        overflow: hidden;
        width: calc(100% + 30px);
        margin-left: -15px;
    }
}

.banks_wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 54px;

    @include mediaToDown($container-tablet) {
        overflow-x: auto;
        margin-bottom: -15px;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }

    @include mediaToDown($container-mobile) {
        padding-top: 25px;
    }
}

.bank_item_wrap {
    flex-grow: 1;
    flex-basis: 0;
    margin: 0 30px;

    &:last-child {
        margin-right: 0;
    }

    &:first-child {
        margin-left: 0;
    }

    @include mediaToDown($container-mobile) {
        margin: 0 20px;
    }
}

.bank_item {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mediaToDown(415px) {
        width: 80px;
    }

    @include mediaToDown(376px) {
        width: 110px;
    }
    @include mediaToDown(340px) {
        width: 90px;
    }
}

.bank_logo_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    margin-bottom: 20px;

    @include mediaToDown($container-mobile) {
        margin-bottom: 10px;
    }
}

.bank_logo {
    width: 100%;

    &.bank_1 {
        width: 60px;
    }

    &.bank_2 {
        width: 60px;
    }

    &.bank_3 {
        width: 70px;
        margin-left: 10px;
    }

    &.bank_4 {
        width: 60px;
    }

    &.bank_5 {
        width: 60px;
        margin-top: -10px;
    }

    &.bank_6 {
        width: 60px;
    }

    &.bank_7 {
        width: 60px;
    }

    &.bank_8 {
        width: 60px;
    }

    &.bank_9 {
        width: 65px;
    }

    &.bank_10 {
        width: 63px;
    }

    img {
        display: block;
        width: 100%;
    }
}

.bank_name {
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: color('grey');

    span {
        display: block;
        font-size: 10px;
        margin-top: 5px;
        line-height: 14px;
        opacity: .62;
    }
}
