%btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    outline: none;
    font-family: $font-family-text;
    font-weight: 500;
    box-shadow: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.08s ease;

    &:disabled {
        background: #C7C7CF;
        box-shadow: none;
        pointer-events: none;
    }

    @at-root button#{&} {
        display: block;
    }
}

%btn--green {
    @extend %btn;
    position: relative;
    height: 80px;
    padding: 20px 38px 20px 42px;
    font-size: 30px;
    line-height: 28px;
    text-align: left;
    background: #FF971D;
    color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: inset 0px -2px 0px #D37D19;
    transition: all 0.2s ease;

    &:hover {
        background: #ff8a00;
        box-shadow: inset 0px -2px 0px #D37D19;
    }

    &:active {
        box-shadow: inset 0px 0 0px #ff8a00;
    }

    &.icon {
        &:after {
            content: '';
            position: absolute;
            right: 40px;
            top: 50%;
            transform: translateY(-50%);
            width: 31px;
            height: 21px;
            background: url('../../images/btn_arrow.svg') no-repeat center center/cover;

            @include mediaToDown($container-mobile) {
                width: 20px;
                height: 14px;
                right: 17px;
            }
        }
    }

    &.load {
        &:before {
            content: '';
            position: absolute;
            right: 80px;
            top: 50%;
            transform: translateY(-50%);
            width: 28px;
            height: 28px;
            background: url('../../images/circlel_load.svg') no-repeat center center/cover;
            animation: rotating 1s linear infinite, moveIn .3s ease-in-out forwards;

            @include mediaToDown($container-mobile) {
                width: 20px;
                height: 20px;
                animation: rotating 1s linear infinite, moveInMobile .3s ease-in-out forwards;
            }
        }

        &.icon {
            &:after {
                animation: moveOut .3s ease-in-out forwards;

                @include mediaToDown($container-mobile) {
                    animation: moveOutMobile .3s ease-in-out forwards;
                }
            }
        }
    }

    @include mediaToDown($container-tablet) {
        font-size: 26px;
    }

    @include mediaToDown($container-mobile) {
        height: 60px;
        font-size: 22px;
        line-height: 22px;
        padding: 16px 20px;
    }
}

.btn_green {
    @extend %btn--green;
}

.react-ripples {
    width: 100%;
    border-radius: 10px;
}

@keyframes rotating {
    from {
        transform: translateY(-50%) rotate(0deg);
    }
    to {
        transform: translateY(-50%) rotate(360deg);
    }
}

@keyframes moveOut {
    from {
        right: 40px;
        opacity: 1;
    }
    to {
        right: 0;
        opacity: 0;
    }
}

@keyframes moveOutMobile {
    from {
        right: 17px;
        opacity: 1;
    }
    to {
        right: 0;
        opacity: 0;
    }
}

@keyframes moveIn {
    from {
        right: 80px;
    }
    to {
        right: 40px;
    }
}

@keyframes moveInMobile {
    from {
        right: 50px;
    }
    to {
        right: 17px;
    }
}
