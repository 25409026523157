.clearfix {
    *zoom: 1;
    @include clearfix();
  }
  
  *:focus {
    outline: none;
  }
  
  .hidden {
    display: none !important;
  }
  
  .typography {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      line-height: 1.3;
    }
  
    h1 {
      font-size: 36px;
    }
  
    h2 {
      font-size: 40px;
    }
  
    h3 {
      font-size: 32px;
    }
  
    h4 {
      font-size: 26px;
    }
  
    h5 {
      font-size: 22px;
    }
  
    h6 {
      font-size: 18px;
    }
  
    textarea {
      overflow: auto;
    }
  
    p {
      margin: 0;
      line-height: 1.4;
    }
  
    img {
      max-width: 100%;
      height: auto;
    }
  
    a {
      text-decoration: none;
  
      @include hover-focus {
        text-decoration: none;
      }
    }
  
    ul,
    .ul {
  
      li {
       
      }
  
    }
  
    option {
      min-height: 0;
      padding: 0;
      line-height: 0;
    }
  
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
      color: #1d2a48;
      -webkit-text-fill-color: #1d2a48;
      background-color: transparent !important;
      box-shadow: 0 0 0 1000px #fff inset !important;
  
      @include mediaToDown($container-mobile) {
        box-shadow: 0 0 0 1000px #fff inset !important;
      }
    }
  
  
    img[align="left"],
    img[align="right"] {
      @include mediaToDown($container-mobile) {
        display: block;
        margin-bottom: 30px;
        width: 100%;
  
        & + * {
          clear: both;
        }
      }
    }
  
    ol {
      padding: 0;
      margin: 0 0 30px;
  
      @include mediaToDown($container-mobile) {
        margin: 0 0 30px;
      }
  
      li {
        position: relative;
        list-style-type: none;
        counter-increment: my-counter;
        margin-top: 7px;
        line-height: 1.4;
  
        @include mediaToDown($container-mobile) {
          margin-top: 25px;
          font-size: 15px;
        }
  
        &:first-child {
          margin-top: 0;
        }
  
        &::before {
          content: counter(my-counter) ". ";
        }
      }
    }
  }
  
  .mobile_only {
    display: none;
  
    @include mediaToDown($container-tablet) {
      display: block;
    }
  }
  
  .no-margin {
    margin: 0 !important;
  }
  
  .modal-open {
    overflow: hidden;
  
    .layout {
      overflow-y: scroll;
    }
  }
  
  .hidden-mobile {
    @include mediaToDown($container-mobile) {
      display: none !important;
    }
  }
  
  .visible-only-desktop {
    display: none !important;
  
    @include mediaToUp($container-tablet) {
      display: block !important;
    }
  }
  
  .visible-only-tablet {
    display: none !important;
  
    @include mediaOnly($container-mobile, $container-tablet) {
      display: block !important;
    }
  }
  
  .visible-only-mobile {
    display: none !important;
  
    @include mediaToDown($container-mobile) {
      display: block !important;
    }
  }
  