// настройки темы
$separate-element: '__';
$separate-modifier: '--';

// styles
$font-size-default: 20px !default;
$font-size-table-default: 16px !default;
$font-size-mobile-default: 14px !default;

// grid
$grid-gutter-width: pxToVwHD(20px) !default;
$grid-gutter-width-mobile: 40px !default;

$container-tablet: 1200px !default;
$container-mobile: 768px !default;

@import './mixins';

$font-family-secondary: 'Cera Pro regular', sans-serif  !default;
$font-family-text: 'Rubik', sans-serif !default;
$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;