*,
::before,
::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
    line-height: 1;
    //touch-action: none;
    color: #4d4d59;
    vertical-align: baseline;
    font-size: $font-size-default;
    font-family: $font-family-text;

    @include mediaToDown($container-tablet) {
        font-size: $font-size-table-default;
    }

    @include mediaToDown($container-mobile) {
        font-size: $font-size-mobile-default;
        background: #F0F2F6;

    }
}

html,
body,
.layout {
    height: 100%;
    width: 100%;
    min-height: 100%;
}

h1.section_title {
    font-size: pxToVwHD(48px);
    text-transform: uppercase;
    font-family: $font-family-text;
    color: #000000;

    @include mediaToDown($container-tablet) {
        font-size: pxToVwLayoutSize(40px);
    }

    @include mediaToDown($container-mobile) {
        font-size: 24px;
    }
}
