.section_credit_consult {
    margin-top: 147px;
    background: #F0F2F6;
    @include mediaToDown($container-mobile) {
        margin-top: 0;
    }
}

.credit_consult_wrap {
    display: flex;
    justify-content: space-between;
    padding-top: 22px;
    @include mediaToDown($container-mobile) {
        padding-top: 0;
    }
}

.credit_consult_left {
    width: calc(100% - 407px);
    padding-bottom: 28px;
    padding-top: 30px;
    @include mediaToDown($container-tablet) {
        width: 100%;
    }
    @include mediaToDown($container-mobile) {
        padding-top: 26px;
        padding-bottom: 0;
    }
}

.credit_consult_right {
    width: 348px;
    margin-bottom: -2px;
    @include mediaToDown($container-tablet) {
        display: none;
    }
}

.credit_consult_form_content {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include mediaToDown($container-mobile) {
        flex-direction: column;
        padding-top: 38px;
    }

    .form_row {
        width: 386px;
        @include mediaToDown($container-tablet) {
            width: 49%;
        }
        @include mediaToDown($container-mobile) {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .form_row_checbox {
        padding-top: 25px;
        @include mediaToDown($container-tablet) {
            text-align: right;
            padding-right: 12%;
        }
        @include mediaToDown($container-mobile) {
            width: 100%;
            padding-right: 0;
            text-align: center;
            padding-top: 0;
        }
    }
}

.credit_consult_text {
    padding-top: 16px;
    font-size: 20px;
    line-height: 28px;
    color: #4D4D59;
    @include mediaToDown($container-mobile) {
        font-size: 16px;
        line-height: 24px;
        padding-top: 20px;

        br {
            display: none;
        }
    }
}

.credit_consult_subtitle {
    padding-top: 20px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    color: #212126;
    @include mediaToDown($container-mobile) {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        padding-top: 14px;
        margin-bottom: 10px;
    }
}

.credit_consult_bank_info {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-left: 55px;
    font-size: 16px;
    line-height: 19px;
    color: #8E8E9E;
    @include mediaToDown($container-mobile) {
        bottom: auto;
        top: 0;
        padding-left: 54px;
        font-size: 13px;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 19px;
        height: 4px;
        background: url('../../images/icon_grey.svg') no-repeat center center/cover;
    }
}
